import { template as template_c745cadb84ee4f62bd8041b08c6a2683 } from "@ember/template-compiler";
import concatClass from "discourse/helpers/concat-class";
const FKSection = template_c745cadb84ee4f62bd8041b08c6a2683(`
  <div class={{concatClass "form-kit__section" @class}} ...attributes>
    {{#if @title}}
      <h2 class="form-kit__section-title">{{@title}}</h2>
    {{/if}}

    {{#if @subtitle}}
      <span class="form-kit__section-subtitle">{{@subtitle}}</span>
    {{/if}}

    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKSection;
